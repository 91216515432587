<template>
	<div class="goods-details"  v-if="details">
		<!-- 用户信息 -->
		<header-user-info types="goodsDetail" :type="types"></header-user-info>
		<mt-swipe :auto="3000" class="swiper">
			<mt-swipe-item v-for="(item, index) in details.show_imgurl" :key="index">
				<img :src="item" />
			</mt-swipe-item>
		</mt-swipe>
		<div class="goods-desc">
			<div class="goods-name">{{ details.goods_name }}</div>
			<div class="goods-price-row">
				<div class="goods-price" v-if="nowSpec">
					￥{{ nowSpec.specs_price }}
					<span>￥{{ nowSpec.market_price }}</span>
				</div>
				<div class="sale-num">已售{{ details.total_sale }}件</div>
			</div>
		</div>
		<!-- 选择规格 -->
		<div class="spec-row" @click="isShowSpec = true">
			<span>选择</span>
			<div class="spec-desc" v-if="nowSpec">{{ nowSpec.item_value }}</div>
			<img src="@/assets/right.png" />
		</div>
		<!-- 图文详情 -->
		<div class="content">
			<div class="tabbar">
				<van-tabs scrollspy sticky offset-top="0.88rem">
					<van-tab title="图文详情">
						<div class="indTabContent">
							<div v-html="goodscontent.long_contents"></div>
						</div>
					</van-tab>
					<van-tab title="购买须知">
						<div class="indTabContent">
							<div v-html="goodscontent.goods_notes"></div>
						</div>
					</van-tab>
				</van-tabs>
			</div>
		</div>
		<!-- 右侧推广按钮 -->
		<div class="share-icon" v-if="userInfo.user_types == 2" @click="toShareGoods"
			v-clipboard:copy="details.goods_name + ' 下单链接: ' + shareLink">
			<p class="price" v-if="nowSpec">
				￥{{ userInfo.referrer_lv == 1 ? nowSpec.commission2 * 1 : nowSpec.commission1 * 1 }}
			</p>
		</div>
		<!-- 底部按钮 -->
		<div class="footer">
			<router-link tag="div" to="/home" class="link-item">
				<img src="@/assets/cp01.png" alt />
				<span>首页</span>
			</router-link>
			<div v-show="userInfo.user_types == 2" class="link-item" @click="toShareGoods"
				v-clipboard:copy="details.goods_name + ' 下单链接: ' + shareLink">
				<img src="@/assets/cp02.png" alt />
				<span>海报</span>
			</div>
			<div class="footer-mid">
				<div class="price" v-if="nowSpec">
					<div class="price-now">
						￥<span>{{ nowSpec.specs_price }}</span>
					</div>
					<div class="price-old">￥{{ nowSpec.market_price }}</div>
				</div>
			</div>
			<div class="buy" v-if="details.goods_stock >0" @click="isShowSpec = true">立即购买</div>
			<div class="buy1" v-else>抢光了</div>
		</div>


		<!-- 选择规格遮罩 -->
		<div class="model" v-if="isShowSpec">
			<div class="model-box">
				<div class="goods-info">
					<img :src="nowSpec.specs_imgurl" class="goods-img" />
					<div class="goods-desc-box">
						<div class="goods-name">{{ details.goods_name }}</div>
						<div class="price-row">
							<div class="goods-price">￥{{ nowSpec.specs_price }}</div>
							<div class="goods-return" v-if="nowSpec && userInfo.user_types == 2">
								饭佣￥{{userInfo.referrer_lv == 1 ? nowSpec.commission2 * 1 : nowSpec.commission1 * 1}}
							</div>
						</div>
					</div>
				</div>
				<div class="spec-box">
					<div class="spec-title">规格</div>
					<div :class="['spec-item', { ac: nowSpecIndex == index }]"
						v-for="(item, index) in details.specs_list" :key="index" @click="nowSpecIndex = index">
						{{ item.item_value }}
					</div>
				</div>
				<div class="num-row">
					<div class="num-title">数量</div>
					<van-stepper v-model="buyNum" :min="stepperMin" :max="stepperMax" :integer="true"
						input-width="2.2rem" button-size="0.58rem" @overlimit="overlimit" />
				</div>
				<div class="model-btn" v-if="nowSpec.specs_stock >0" @click="checkCards">立即购买</div>
				<div class="model-btn1" v-else>抢光了</div>
			</div>
			<div class="model" @click="isShowSpec = false"></div>
		</div>
		<share-poster ref="sharePoster" :goodsDetails="{goods_name:details.goods_name,share_imgurl:details.share_imgurl}" :userInfo="userInfo" :shareLink="shareLink" @close="closePoster" v-if="showPoster"></share-poster>
	</div>
    <div v-else>
        <van-empty description="商品详情">
            <router-link to="/home" v-if="goods_tips" >
                <van-button round type="danger" class="bottom-button">返回首页</van-button>
            </router-link>    
        </van-empty>
    </div>
</template>

<script>
	import {
		getGoodsContent,
		getGoodsDetails,
		checkCards
	} from "@/http/api";
	import {
		onUserShare
	} from "@/common/wxJssdk";
	import SharePoster from "@/views/details/detailsChildren/SharePoster";
	import HeaderUserInfo from "@/views/home/children/Header";
	export default {
		components: {
			SharePoster,
			HeaderUserInfo
		},
		data() {
			return {
                goods_tips: false,
				userToken: "",
				types: 1,
				share_id: 0, //  分享ID
				referrer_id: 0, //  推广员ID
				details: {},
				nowSpecIndex: 0,
				isShowSpec: false,
				userInfo: {},
				showNum: 1,
				showPoster: false,
				shareLink: "",
				isClickShare: false,
				buyNum: 1,
				version: "",
				goods_id: "",
				goodscontent: {} //图文详情
			};
		},
		mounted() {
			let route = this.$route.query;
			this.userToken = this.$LStorage.getItem("userToken").user_token;
			this.userInfo = this.$LStorage.getItem("userInfo");
			if (route.types) {
				this.types = route.types
			}
			if (route.goods_id) {
				this.goods_id = route.goods_id;
				this.version = "2.0";
			}
			if (route.id) {
				this.goods_id = route.id;
				this.version = "1.0";
			}
			if (this.goods_id) {
				//  处理分享
				if (this.types == "2" && route.referrer_id) {
					this.referrer_id = route.referrer_id;
				} else {
					this.referrer_id = this.$LStorage.getItem("referrerInfo") ? this.$LStorage.getItem("referrerInfo") : 0;
				} 
				this.getGoodsDetails();
			} else {
				this.$dialog.alert({
					message: '商品页面参数异常, 点击跳转到首页看看',
				}).then(() => {
					this.$router.replace({
						path: "/home"
					});
				});
			}
			//console.log(this.$data)
		},
		methods: {
			//获取图文详情
			async getGoodsContent() {
				const res = await getGoodsContent({
					data: {
						goods_id: this.goods_id
					}
				});
				this.goodscontent = res.data;
			},
			// 获取商品详情
			async getGoodsDetails() {
				const res = await getGoodsDetails({
					data: {
						goods_id: this.goods_id,
						user_token: this.userToken,
						referrer_id: this.referrer_id,
						share_id: this.share_id,
						version: this.version
					}
				});
				if (res.code == 200) {
					this.details = res.data;
					this.getGoodsContent();
					// 执行创建分享;
					this.onUserShareGoods({
						title: res.data.goods_name,
						imgUrl: res.data.cover_imgurl
					});
				} else {
					this.$toast.fail(res.msgs);
                    this.goods_tips = true;
				}
			},

			//  商品分享
			onUserShareGoods(goods) {
				let site_code = this.$LStorage.getItem("curSiteInfo").site_code;
				if (this.userInfo.user_types == 1) {
					goods.link = "/ShopGoodsDetails?goods_id=" + this.goods_id + "&types=1&site_code=" + site_code;
					goods.desc = "饭十荟带您花的少吃的好";
				} else {
					goods.link = "/ShopGoodsDetails?goods_id=" + this.goods_id + "&types=2&referrer_id=" + this.userInfo
						.user_code + "&site_code=" + site_code;
					goods.desc = "饭十荟【" + this.userInfo.user_name + "】掌柜带您花的少吃的好";
				}
				this.shareLink = window.location.origin + goods.link;
				onUserShare(goods);
			},

			//  点击分享
			toShareGoods() {
				if (this.isClickShare) {
					// this.$toast.loading('正在创建')
					this.showPoster = true;
				} else {
					this.isClickShare = true;
					this.$toast.loading("正在创建");
					this.$toast.clear();
					this.showPoster = true;
				}
			},

			//  关闭分享海报
			closePoster() {
				this.showPoster = false;
				// this.$refs.sharePoster.isShow = true
			},

			//  达人创建商品分享
			async createShare() {
				if (this.isClickShare) {
					// this.$toast.loading('正在创建')
					this.showPoster = true;
				} else {
					this.isClickShare = true;
					this.$toast.loading("正在创建");
					this.$toast.clear();
					this.showPoster = true;
				}
			},

			// 限制购买数量
			overlimit(e) {
				if (e == "minus") {
					if (this.nowSpec.specs_stock == 0) {
						this.$toast("不能再少啦");
					} else {
						this.$toast(this.details.buy_least + "件起购");
					}
				} else {
					if (this.details.buy_quota > 0) {
						this.$toast("限购" + this.stepperMax + "份");
					} else {
						this.$toast("库存不足");
					}
				}
			},
			// 商品预结算
			async checkCards() {
				if (this.buyNum <= 0) {
					this.$toast("库存不足");
					return;
				}
				const res = await checkCards({
					data: {
						user_token: this.userToken,
						goods_id: this.goods_id,
						goods_nums: this.buyNum,
						goods_specs_id: this.nowSpec.goods_specs_id
					}
				});
				if (res.code == 200) {
					this.$router.push({
						path: "/ShopMallSettlement",
						query: {
							id: res.data.buffer_id,
							share_id: this.share_id,
							referrer_id: this.referrer_id
						}
					});
				} else {
					this.$toast.fail(res.msgs);
				}
			}
		},

		computed: {
			nowSpec() {
				let nowSpecIndex = this.nowSpecIndex;
				if (this.details.specs_list) {
					return this.details.specs_list[nowSpecIndex];
				}
			},
			// 计算最大购买数量
			stepperMax() {
				let buyQ = this.details.buy_quota;
				if (buyQ == 0) {
					return this.nowSpec.specs_stock;
				} else {
					return buyQ;
				}
			},
			// 计算最小购买数量
			stepperMin() {
				let buyL = this.details.buy_least;
				if (this.nowSpec.specs_stock == 0) {
					return 0;
				} else {
					return buyL;
				}
			}
		},
		filters: {}
	};
</script>

<style lang="scss" scoped>
	.goods-details {
		box-sizing: border-box;
		position: relative;
		width: 100%;

		* {
			box-sizing: border-box;
		}

		.home-nav {
			width: 100%;
			background: none;
			padding: 0;
			height: auto;
			z-index: 888;

			/deep/ .user-info {
				background-color: rgba(254, 230, 218, 1);
				height: 0.88rem;
				width: 100%;
				box-sizing: border-box;
				padding: 0 0.3rem;
				position: fixed;
				left: 0;
				top: 0;
				z-index: 999;
				display: flex;
				align-items: center;

				.logo {
					width: 1.74rem;
				}

				.user-box {
					height: 100%;
					display: flex;
					align-items: center;

					.headimg {
						width: 0.58rem;
						height: 0.58rem;
						border-radius: 50%;
						margin-right: 0.12rem;
					}

					.nickname {
						font-size: 0.3rem;
						color: #333;
						width: 2rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}

		.swiper {
			height: 100vw;
		}

		.mint-swipe-item img {
			height: 100vw;
			width: 100%;
			object-fit: cover;
		}

		.goods-desc {
			padding: 3vw;
			border-bottom: 10px solid #f6f6f6;

			.goods-name {
				font-size: 0.3rem;
				line-height: 1.6;
				/*display: -webkit-box; */
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				margin-bottom: 10px;
			}

			.goods-price-row {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;

				.goods-price {
					color: #da1313;
					font-size: 0.32rem;
					font-weight: 600;

					span {
						font-size: 0.24rem;
						font-weight: 400;
						color: #323233;
						text-decoration: line-through;
					}
				}

				.sale-num {
					font-size: 0.28rem;
				}
			}
		}

		.spec-row {
			height: 1.35rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 3vw;
			border-bottom: 10px solid #f6f6f6;

			span {
				font-size: 0.28rem;
				color: #a2a2a2;
			}

			div {
				font-size: 0.28rem;
				width: 72vw;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			img {
				height: 0.3rem;
				width: auto;
			}
		}

		.content {
			font-size: 0.28rem;
			margin-bottom: 1.4rem;

			.tabbar {
				width: 100%;
				margin-top: 0.2rem;
				background: #fff;

				.indTabContent {
					padding: 0.24rem;
					font-size: 0.3rem;
					overflow: hidden;

					/deep/ img {
						width: 100% !important;
						display: block;
					}
				}
			}
		}

		// 右侧悬浮分享
		.share-icon {
			width: 2.15rem;
			height: 1.74rem;
			position: fixed;
			right: 0.1rem;
			bottom: 16%;
			background: url("../../assets/cp06.png") no-repeat center;
			background-size: cover;

			.price {
				font-size: 0.4rem;
				color: #eb1200;
				text-align: center;
				padding-top: 0.06rem;
			}
		}

		.footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: fixed;
			left: 0;
			bottom: 0;
			background: #fff;
			width: 100vw;
			height: 1.16rem;
			font-size: 0.24rem;
			padding: 0 3vw;
			z-index: 800;

			.link-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				img {
					width: 0.3rem;
					height: 0.3rem;
					margin-bottom: 0.15rem;
				}
			}

			.footer-mid {
				display: flex;
				flex-direction: column;

				.price {
					display: flex;
					align-items: center;
					margin-bottom: 5px;

					.price-now {
						color: #e50012;

						span {
							font-size: 0.36rem;
						}
					}

					.price-old {
						margin-left: 5px;
						color: #c8c8c8;
						text-decoration: line-through;
					}
				}

				.return-btn {
					border: 1px solid #e50012;
					color: #e50012;
					padding: 0 0.1rem;
					height: 0.4rem;
					border-radius: 5px;
					background: #fee3e5;
				}
			}

			.buy {
				width: 2.32rem;
				height: 0.84rem;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 0.84rem;
				color: #fff;
				background: #e50012;
				font-size: 0.3rem;
				font-weight: bold;
			}

			.buy1 {
				width: 2.32rem;
				height: 0.84rem;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 0.84rem;
				color: #fff;
				background: #b3b3b3;
				font-size: 0.3rem;
				font-weight: bold;
			}
		}

		.model {
			background: rgba(0, 0, 0, 0.5);
			width: 100vw;
			height: 100vh;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 1000;

			.model-box {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100vw;
				background: #fff;
				padding: 3vw;
				box-sizing: border-box;
				z-index: 1001;

				.goods-info {
					display: flex;
					align-items: center;

					.goods-img {
						width: 22.8vw;
						height: 22.8vw;
						border-radius: 5px;
						margin-right: 3vw;
					}

					.goods-desc-box {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						height: 22.8vw;

						.goods-name {
							font-size: 0.28rem;
							line-height: 1.6;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}

						.price-row {
							display: flex;
							align-items: cneter;
							justify-content: space-between;

							.goods-price {
								color: #da1313;
								font-size: 0.32rem;
								font-weight: 600;
								margin-bottom: 10px;

								span {
									font-size: 0.24rem;
									font-weight: 400;
								}
							}

							.goods-return {
								height: 0.4rem;
								// width: 7em;
								font-size: 0.26rem;
								background: #ff6300;
								color: #fff;
								padding: 0 0.2rem;
								border-radius: 0.4rem;
								display: inline;
								text-align: center;
							}
						}
					}
				}

				.spec-box {
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.spec-title {
						font-size: 0.28rem;
						color: #323232;
						width: 100%;
						height: 0.92rem;
						display: flex;
						align-items: center;
					}

					.spec-item {
						padding: 0 0.35rem;
						border-radius: 5px;
						background: #f1f1f1;
						color: #000;
						font-size: 0.26rem;
						height: 0.63rem;
						display: flex;
						align-items: center;
						margin-right: 10px;
						margin-bottom: 10px;
					}

					.spec-item.ac {
						background: #fce0e0;
					}
				}

				.num-row {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 10px 0;

					.num-title {
						font-size: 0.28rem;
						color: #323232;
					}

					.goods-num {
						display: flex;
						align-items: center;
						height: 0.7rem;
						border: 1px solid #eee;
						font-size: 0.36rem;

						span {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 0.7rem;
							width: 1rem;
						}

						div {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 0.7rem;
							width: 1.26rem;
							font-size: 0.26rem;
							border-left: 1px solid #eee;
							border-right: 1px solid #eee;
						}
					}
				}

				.tips {
					color: #e50012;
					font-size: 0.26rem;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px 0;
				}

				.model-btn {
					width: 90.8vw;
					height: 0.84rem;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 3vw 1.1vw;
					font-size: 0.3rem;
					color: #fff;
					background: #e50012;
					border-radius: 0.84rem;
				}

				.model-btn1 {
					width: 90.8vw;
					height: 0.84rem;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 3vw 1.1vw;
					font-size: 0.3rem;
					color: #fff;
					background: #b3b3b3;
					border-radius: 0.84rem;
				}

				.model-btn.gray {
					background: #e6e4e4;
					color: #fff;
				}
			}
		}
	}
</style>
