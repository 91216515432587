<!-- 分享海报 -->
<template>
	<div class="share-poster">
		<!-- <canvas class="canvas" width="375" height="724" id="myCanvas"></canvas> -->
		<!-- <img :src="imgUrl" alt=""> -->
		<!-- <div style="margin: 10px">生成海报图</div> -->
		<vue-canvas-poster :widthPixels="1000" :painting="painting" @success="canvasSuccess" @fail="canvasFail">
		</vue-canvas-poster>
		<div class="share-content">
			<div class="text">已复制：{{ goodsDetails.goods_name }}</div>
			<img style="width: 100%; height: auto" :src="posterImg" alt />
			<div class="save">
				<img src="@/assets/cp07.png" class="icon" alt />
				<span>长按图片保存到相册</span>
			</div>
		</div>
		<div class="cover" @click="close"></div>
	</div>
</template>

<script>
	import QRCode from "qrcodejs2"; //生成二维码
	import html2canvas from "html2canvas"; //html转图片
	import {
		getBase64Image
	} from "@/common/data";

	export default {
		components: {},
		props: {
			goodsDetails: {
				type: Object,
				default () {
					return {};
				}
			},
			shareLink: {
				type: String,
				default () {
					return "";
				}
			},
			userInfo: {
				type: Object,
				default () {
					return {};
				}
			}
		},
		// 接收父组件参数
		data() {
			return {
				imgUrl: "",
				imgBgUrl: "",
				isShow: false,
				qrcode: "",
				posterImg: "",
				painting: {
					width: "750px",
					height: "1334px",
					background: this.goodsDetails.share_imgurl,
					views: [{
							type: "text",
							text: this.userInfo.nickname,
							css: {
								bottom: "50px",
								right: "30px",
								width: "125px",
								color: "#fff",
								maxLines: 1,
								fontSize: "26px",
								textAlign: "center"
							}
						},
						{
							type: "qrcode",
							content: this.shareLink,
							css: {
								bottom: "100px",
								right: "30px",
								color: "#000",
								background: "#fff",
								width: "120px",
								height: "120px",
								borderWidth: "5px",
								borderColor: "#fff"
							}
						}
					]
				}
			};
		},
		//方法集合
		methods: {
			/**
			 * 成功生成海报
			 */
			canvasSuccess(src) {
				this.posterImg = src; //生成是base64格式的图片
				// console.log(src)

				this.$toast.clear();
			},

			/**
			 * 无法生成海报
			 */
			canvasFail(err) {
				this.$toast(err);
			},
			// 关闭
			close() {
				this.$emit("close");
			}
		},
		//生命周期 - 挂载完成（可以访问DOM元素）
		mounted() {
			// this.imgBgUrl = this.goodsDetails.share_imgurl;
			// this.creatQrCode(this.shareLink)
			this.$toast.loading("生成中");
		},
	};
</script>
<style lang='scss' scoped>
	//@import url(); 引入公共css类
	// 分享海报
	.share-poster {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		z-index: 999;

		.cover {
			background: rgba(0, 0, 0, 0.63);
			width: 100%;
			height: 100%;
		}

		.share-content {
			position: absolute;
			height: max-content;
			width: 80vw;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			font-size: 0.3rem;
			color: #fff;

			.text {
				text-overflow: -o-ellipsis-lastline;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				line-clamp: 2;
				-webkit-box-orient: vertical;
				margin-bottom: 0.2rem;
			}

			.poster-box {
				width: 100%;
				height: 70vh;
				overflow: hidden;
				position: relative;
				// background: url("") no-repeat center;
				background-size: 100%;

				.poster {
					width: 100%;
				}

				.qrcode-box {
					position: absolute;
					right: 0.14rem;
					bottom: 0.24rem;
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: max-content;
					height: max-content;
					align-items: center;

					.qrcode {
						background: #fff;
						padding: 0.08rem;
					}

					p {
						text-align: center;
						// background-color: #fff;
						color: #ffffff;
						font-size: 0.22rem;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						width: 1.65rem;
						margin: 0 auto;
					}
				}
			}

			.save {
				text-align: center;
				margin-top: 0.5rem;

				.icon {
					width: 0.63rem;
					height: 0.64rem;
					display: inline-block;
					margin-right: 0.2rem;
				}
			}
		}
	}
</style>
